
if (window.location.hash) scroll(0,0);

setTimeout(function(){scroll(0,0);},1);

let h;

if (window.innerWidth > 992) {
    $('.header__menuItem--hasSubmenu > a').css("pointer-events", "none");
    h = 115;
} else {
    h = 55;
}

if(window.location.hash){

    $(document).ready(function() {
        $('html,body').animate({
            scrollTop:$(window.location.hash).offset().top - h + 'px'
        },1500,'swing');
    })

}

window.addEventListener("hashchange", function () {
    window.scrollTo(window.scrollX, window.scrollY - h);
});
