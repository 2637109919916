import LazyLoad from "vanilla-lazyload";
require("./rwd-checker");
require("./form-animation");

$(function() {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    // aby użyć do elementów dodajemy klasę .lazy
    // zamiast src w img podajemy data-src
    // zamiast background-image podajemy data-bg
  });

  require("./cookie-bar");
  require("./animations");
  require("./counter-animation");
  require("./dropdown-menu");
  require("./mobile-menu");
  require("./sticky-header");
  require("./pop-up");
  require("./scroll-smooth");


  $(".gallery").each(function() {
    $(this)
      .find("a")
      .simpleLightbox();
  });
  if ($(".simplelightbox").length > 0) {
    $(".simplelightbox").simpleLightbox();
  }
});


$('.home-slider').slick({
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  fade: true,
  autoplay: true,
  pauseOnHover: false,
  cssEase: 'linear'
});


$(document).ready(function() {
  $('.grid-container a').on('click', function(e) {
    if(!$(this).hasClass('touched') && $(this).css('opacity') == '0')
    {
      event.preventDefault();
      $('.grid-container a').removeClass("touched");
       $(this).addClass("touched");
    }
    else
    {
       
    }
  });
});


$('.intro__slider').slick({
  dots: false,
  arrows: false,
  infinite: true,
  speed: 2000,
  fade: true,
  autoplay: true,
  pauseOnHover: false,
  cssEase: 'linear'
});
