/* mobile menu */

if (window.innerWidth < 992) {
    $('.header__menuList a, .header__hamburger').click(function(){
        $('.header__hamburger').toggleClass('header__hamburger--open');
        $('html, body').toggleClass('blocked');
        $('.header__nav').toggleClass('header__nav--visible');
    });
} else {
    $('.header__hamburger').click(function(){
        $('.header__hamburger').toggleClass('header__hamburger--open');
        $('html, body').toggleClass('blocked');
        $('.header__nav').toggleClass('header__nav--visible');
    });
}
